import { render, staticRenderFns } from "./idTransparencia.vue?vue&type=template&id=4a36cb32&scoped=true&"
import script from "./idTransparencia.ts?vue&type=script&lang=ts&"
export * from "./idTransparencia.ts?vue&type=script&lang=ts&"
import style0 from "./idTransparencia.vue?vue&type=style&index=0&id=4a36cb32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a36cb32",
  null
  
)

export default component.exports