import { Component, Vue } from 'vue-property-decorator'
import Layout from '@/layouts/basic/basic.vue';
import LicitacaoItem from '@/modules/licitacoes/components/licitacaoItem/licitacaoItem.vue';
import {} from '@/components/base';
  
@Component({
  components: {
    Layout,
    LicitacaoItem
  }
})
export default class IdTransparencia extends Vue {

}
  